import {
  Box,
  Flex,
  Button,
  Wrap,
  Spacer,
  Tooltip,
  useDisclosure,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Text,
  HStack,
  Collapse,
  IconButton
} from "@chakra-ui/react";

import {
  Link as RouterLink,
} from "react-router-dom";

import { FaTwitter, FaDiscord } from "react-icons/fa";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";

import Web3Service from "../core/web3.service";

interface NavItem {
  label: string;
  children?: Array<NavItem>;
  href?: string;
  isExternal?: boolean;
}

interface HeaderNavigationProps {
  isConnected: boolean
  account: string | undefined
  isCorrectChainId: boolean
  toggleConnect: () => void
  switchNetwork: () => void
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {
  const NAVIGATION_ITEMS: Array<NavItem> = [
    {
      label: 'Home',
      href: '/',
    },
    {
      label: 'Explore',
      href: '/explore',
    },
    {
      label: 'Royalties',
      href: '/royalties',
    },
  ]

  const { isOpen, onToggle, onClose } = useDisclosure();

  const account = props.account

  const handleTwitterClick = () => {
    window.open("https://twitter.com/cryptoSBClub");
  }

  const handleDiscordClick = () => {
    window.open("https://discord.gg/rjnNNe5K");
  }

  const popoverContentBgColor = "rgba(0, 0, 0, 0.8)"

  const items = <>
    {NAVIGATION_ITEMS.map((navItem) => (
      <Box key={navItem.label}>
        {navItem.children
          ?
          <>
            <Menu>
              <MenuButton as={Button} variant={'ghost'} rightIcon={navItem.children ? <ChevronDownIcon /> : null}>
                {navItem.label}
              </MenuButton>
              <MenuList
                bgColor={popoverContentBgColor}
                borderColor={popoverContentBgColor}
              >
                {navItem.children.map((child, index) => (
                  <MenuItem key={index} _focus={{}} onClick={() => {
                    onClose();
                  }}>
                    <SubNav key={child.label} {...child} />
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </>
          :
          navItem.isExternal ?
            <Button
              as={Link}
              variant={'ghost'}
              style={{ textDecoration: 'none' }}
              isExternal
              href={navItem.href ?? '#'}
            >
              {navItem.label}
            </Button>
            :
            <RouterLink to={navItem.href ?? '#'}>
              <Button onClick={() => {
                onClose();
              }} variant={'ghost'}>{navItem.label}</Button>
            </RouterLink>}
      </Box>
    ))}
  </>

  const desktopNavigation = <>
    <Stack direction={'row'} spacing={2}>
      <Wrap justify='center'>
        {items}
      </Wrap>
    </Stack>
  </>

  const mobileNavigation = <>
    <Stack
      display={{ md: 'none' }}>
      {items}
    </Stack>
  </>


  return (
    <>
      <Box
        minW={360}
        mx={4}
        mb={4}
        bg={"rgba(0, 0, 0, 0.25)"}
        rounded={"xl"}
        boxShadow={"lg"}
        sx={{ position: "sticky", top: "4", zIndex: "999" }}
        px={4}
      >
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <HStack>
            <IconButton
              onClick={onToggle}
              display={{ base: 'flex', md: 'none' }}
              icon={
                isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
              }
              variant={'ghost'}
              aria-label={'Toggle Navigation'}
            />
            {/* <Link href={'/'}>
              <Image w={{ base: '48px', md: '64px' }} src={LogoImage} />
            </Link> */}
          </HStack>

          <Flex display={{ base: 'none', md: 'flex' }} py={4}>
            {desktopNavigation}
          </Flex>
          <Spacer />

          <HStack px={2}>
            <HStack>
              {Web3Service.shared().isCorrectChainId() ? undefined : <Button colorScheme={'blue'} size={'sm'} onClick={props.switchNetwork}>
                Switch To Cronos
              </Button>}
              <Tooltip label={account ? `${account.slice(0, 6)}...${account.slice(-4)}` : null}>
                <Button size={'sm'} onClick={props.toggleConnect}>
                  {props.isConnected ? "Disconnect" : "Connect Wallet"}
                </Button>
              </Tooltip>
            </HStack>

            <Stack
              direction={'row'}
              spacing={2}>
              <IconButton variant="solid" icon={<FaTwitter />} aria-label={"Twitter"} onClick={handleTwitterClick} />
              <IconButton variant="solid" icon={<FaDiscord />} aria-label={"Discord"} onClick={handleDiscordClick} />
            </Stack>
          </HStack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          {mobileNavigation}
        </Collapse>
      </Box>
    </>
  );
};

const SubNav = ({ label, href, isExternal }: NavItem) => {
  const link = <>
    <Box>
      <Text p={2}
        transition={'all .3s ease'}
        _hover={{ color: 'orange' }}
        fontWeight={400}>
        {label}
      </Text>
    </Box>
  </>

  if (isExternal) {
    return (
      <Link
        style={{ textDecoration: 'none' }}
        isExternal
        href={href ?? "#"}
      >
        {link}
      </Link>
    );
  }
  else {
    return (
      <RouterLink
        to={href ?? "#"}
      >
        {link}
      </RouterLink>
    );
  }
};

export default HeaderNavigation;
