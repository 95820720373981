import {
    Box,
    Center,
    SimpleGrid,
    Text,
    Container,
    Button,
    useColorModeValue,
    Stat,
    StatLabel,
    StatNumber,
    Image
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { merge, tap } from "rxjs";
import { RoyaltiesInfo } from "../core/RoyaltiesInfo";
import Web3Service, { nFormatter } from "../core/web3.service";

interface RoyaltiesClaimerProps { }

const RoyaltiesClaimer: React.FC<RoyaltiesClaimerProps> = (props) => {
    const web3Service = Web3Service.shared();

    const [account, setAccount] = useState<string | undefined>(undefined);
    const [royalties, setRoyalties] = useState<RoyaltiesInfo | undefined>(undefined);

    useEffect(() => {
        web3Service.getRoyalties();

        const account$ = web3Service.account$.pipe(
            tap((account) => {
                setAccount(account);
            })
        );

        const royalties$ = web3Service.royalties$.pipe(
            tap((royalties) => {
                setRoyalties(royalties);
            })
        );

        const subscription = merge(
            royalties$,
            account$
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, [account]);

    return (
        <>
            <Center>
                <Box my={8} textColor={'rgb(255, 255, 255)'}>
                    {account && RoyaltiesSection("Royalties", royalties?.availableToClaim ?? 0, royalties?.totalRoyalties ?? 0, web3Service.claimAllRoyalties)}
                    {!account && (<Container>
                        <Center mt={8}>
                            <Text maxW={'2xl'}>Connect Wallet to check your royalties</Text>
                        </Center>
                        <Center mt={8}>
                            <Button size={{ base: 'sm', sm: 'sm', md: 'md' }} onClick={web3Service.toggleConnect}>
                                {"Connect Wallet"}
                            </Button>
                        </Center>
                    </Container>)}
                </Box>
            </Center>
        </>
    );
};

export default RoyaltiesClaimer;

function RoyaltiesSection(title: string, available: number, total: number, claim: React.MouseEventHandler<HTMLButtonElement>) {
    let formattedTotalRoyalties = total > 1000 ? nFormatter(total, 2) : total;
    let formattedAvailableRoyalties = available > 1000 ? nFormatter(available, 2) : available.toFixed(2);

    return (
        <>
            <SimpleGrid
                textColor={'rgb(255, 255, 255)'}
                columns={{ base: 1, md: 2 }}
                spacing={{ base: 4, md: 8 }}
                my={{ base: 4, md: 8 }}
                mx={"auto"}
                maxW={"6xl"}
            >
                <Stat bg={"rgba(0, 0, 0, 0.25)"}
                    rounded={"xl"}
                    boxShadow={"md"}
                    maxWidth={480}
                    p={8}>
                    <StatLabel>Available to claim</StatLabel>
                    <StatNumber>{formattedAvailableRoyalties} CRO</StatNumber>
                </Stat>

                <Stat bg={"rgba(0, 0, 0, 0.25)"}
                    rounded={"xl"}
                    boxShadow={"md"}
                    maxWidth={480}
                    p={8}>
                    <StatLabel>Total Distributed</StatLabel>
                    <StatNumber>{formattedTotalRoyalties} CRO</StatNumber>
                </Stat>
            </SimpleGrid>

            <Center>
                <Button
                    colorScheme='brand'
                    variant={'solid'}
                    onClick={claim}
                >
                    {`Claim`}
                </Button>
            </Center>
        </>
    );
}