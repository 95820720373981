import {
    Container,
    Stack,
    Box,
    Heading,
    Text,
    Button,
    Center,
    SimpleGrid,
    VStack,
    HStack,
    Image,
} from '@chakra-ui/react';

import { merge, tap } from "rxjs";

import { useEffect, useState } from "react";
import { MintInfo } from "../core/MintInfo";
import Web3Service from "../core/web3.service";
import HeaderImage from "../images/header.webp";

export default function MintHeader() {
    const web3Service = Web3Service.shared();

    const [mintCount, setMintCount] = useState(1)

    const [account, setAccount] = useState<string | undefined>(undefined);
    const [mintInfo, setMintInfo] = useState<MintInfo | undefined>(undefined);

    useEffect(() => {
        web3Service.getMintInfo();

        const account$ = web3Service.account$.pipe(
            tap((account) => {
                setAccount(account);
            })
        );

        const mintInfo$ = web3Service.mintInfo$.pipe(
            tap((mintInfo) => {
                setMintInfo(mintInfo);
            })
        );

        const subscription = merge(
            mintInfo$,
            account$
        ).subscribe();

        return () => {
            subscription.unsubscribe();
        };
    }, [account]);

    let buttonEnabled: boolean
    let buttonTitle: string

    let dateInfo
    let mintsLeft = 0

    if (mintInfo) {
        const now = new Date()
        const publicDate = new Date(Number(mintInfo.publicTimestamp) * 1000)

        dateInfo = <>
            <SimpleGrid
                pt={8}
                textAlign='center'
                columns={{ base: 1, md: 1 }}
                spacing={{ base: 2, md: 2 }}
                mx={"auto"}
                px={4}
            >
                <Heading fontSize={'2xl'}>Date</Heading>
                <Text fontWeight={'bold'} textShadow={'lg'}>{publicDate.toLocaleString(undefined, { dateStyle: 'short', timeStyle: 'short' })}</Text>
            </SimpleGrid>
        </>

        if (mintInfo.paused) {
            buttonTitle = "Paused"
            buttonEnabled = false
        } else if (now >= publicDate) {
            if (Number(mintInfo.maxSupply) > Number(mintInfo.supply)) {
                mintsLeft = Math.min(mintInfo.maxMint, Number(mintInfo.maxSupply) - Number(mintInfo.supply))
                buttonTitle = "Mint"
                buttonEnabled = true
            } else {
                buttonTitle = "Sold Out"
                buttonEnabled = false
            }
        } else {
            buttonTitle = "Coming Soon"
            buttonEnabled = false
        }

        if (mintCount > mintsLeft) {
            setMintCount(mintsLeft)
        }
    } else {
        buttonTitle = "Loading"
        buttonEnabled = false
    }

    let startInfo = <>
        {dateInfo}

        <SimpleGrid
            pt={8}
            textAlign='center'
            columns={{ base: 1, md: 1 }}
            spacing={{ base: 2, md: 2 }}
            mx={"auto"}
            px={4}
        >
            <Heading fontSize={'2xl'}>Price</Heading>
            <Text fontWeight={'bold'} textShadow={'lg'}>300 CRO</Text>
        </SimpleGrid>
    </>

    let amountSlider = <>
        <Center paddingTop={8}>
            <VStack>
                {mintsLeft > 0 && <HStack>
                    <Button onClick={() => {
                        const value = mintCount - 1;
                        setMintCount(Math.max(1, value))
                    }}>-</Button>
                    <Heading fontSize={'md'} px={2}>{mintCount}</Heading>
                    <Button onClick={() => {
                        const value = mintCount + 1;
                        setMintCount(Math.min(mintsLeft, value))
                    }}>+</Button>
                </HStack>}
            </VStack>
        </Center>
    </>

    let mintedStatsView = <>
        <Center pt={4}>
            <VStack>
                {startInfo}
                <Heading pt={4} fontSize={'xl'} > {mintInfo?.supply.toString() ?? '-'}/{mintInfo?.maxSupply.toString() ?? '-'} minted</Heading>
            </VStack>
        </Center>
    </>


    return (
        <Container>
            <Stack
                align={'center'}
                spacing={{ base: 8, md: 10 }}
            >
                <Stack flex={1}>
                    <Image src={HeaderImage} />
                    <Heading
                        lineHeight={1.1}
                        fontWeight={600}
                        fontSize={{ base: '2xl', sm: '3xl', lg: '4xl' }}>
                        <Text
                            as={'span'}
                            textShadow={'lg'}
                            position={'relative'}
                            _after={{
                                content: "''",
                                width: 'full',
                                height: '50%',
                                position: 'absolute',
                                bottom: 1,
                                left: 0,
                                bg: 'blue.900',
                                zIndex: -1,
                            }}>
                            Crypto Sportsbook Club
                        </Text>
                        <br />
                        <Text color={'blue.600'} fontSize={'lg'} textShadow={'lg'}>
                            777 Membership Cards
                        </Text>
                    </Heading>
                    <Box paddingTop={8} textAlign={"center"} fontSize={{ base: 'md', sm: 'md', md: 'lg' }}>
                        777 NFT collection designed for gamblers to buy into an exclusive crypto Sportsbook club. This collection will be an entry into bankrolling the only NFT project of its kind.
                    </Box>

                    <Center>
                        <Box position={"relative"} maxWidth={640}>
                            {mintedStatsView}
                            {mintsLeft > 0 ? amountSlider : undefined}

                            <Center>
                                <HStack mt={8}>
                                    <Button
                                        onClick={() => web3Service.mint(mintCount)}
                                        disabled={!buttonEnabled}
                                        variant={"solid"}
                                        colorScheme={"brand"}
                                    >
                                        {buttonTitle}
                                    </Button>
                                </HStack>
                            </Center>
                        </Box>
                    </Center>
                </Stack>
            </Stack>
        </Container>
    );
}