import { BigNumber, ethers } from "ethers";
import Web3Modal from "web3modal";
import { Subject, BehaviorSubject } from 'rxjs'
import { CONTRACT_ADDRESS, CONTRACT_ABI } from ".././config"
import { MintInfo } from "./MintInfo";
import { ChainModel } from "./ChainModel";

import WalletConnectProvider from "@walletconnect/web3-provider";
import { RoyaltiesInfo } from "./RoyaltiesInfo";

export function nFormatter(num: number, digits: number) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];

    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : num.toFixed(2);

}

export default class Web3Service {
    static instance: Web3Service

    static shared() {
        if (Web3Service.instance) {
            return Web3Service.instance
        } else {
            Web3Service.instance = new Web3Service()
            return Web3Service.instance
        }
    }

    static cronosRpc = "https://mmf-rpc.xstaking.sg";
    static defaultProvider = new ethers.providers.JsonRpcProvider(Web3Service.cronosRpc);

    // Private
    private _connected$ = new BehaviorSubject<boolean>(false)
    private _isLoading$ = new BehaviorSubject<boolean>(false)
    private _account$ = new BehaviorSubject<string | undefined>(undefined)
    private _mintInfo$ = new BehaviorSubject<MintInfo | undefined>(undefined)
    private _royalties$ = new BehaviorSubject<RoyaltiesInfo | undefined>(undefined)
    private _tokens$ = new BehaviorSubject<number[]>([])
    private _signature$ = new BehaviorSubject<string | undefined>(undefined)
    private _showToast$ = new Subject<{ title: string }>()
    private _errors$ = new Subject<string>()
    private _isWhitelisted$ = new BehaviorSubject<boolean>(false)
    private _freeMintCount$ = new BehaviorSubject<number>(0)

    // Public
    public readonly connected$ = this._connected$.asObservable()
    public readonly account$ = this._account$.asObservable()
    public readonly mintInfo$ = this._mintInfo$.asObservable()
    public readonly royalties$ = this._royalties$.asObservable()
    public readonly tokens$ = this._tokens$.asObservable()
    public readonly signature$ = this._signature$.asObservable()
    public readonly showToast$ = this._showToast$.asObservable()
    public readonly errors$ = this._errors$.asObservable()
    public readonly isLoading$ = this._isLoading$.asObservable()
    public readonly isWhitelisted$ = this._isWhitelisted$.asObservable()
    public readonly freeMintCount$ = this._freeMintCount$.asObservable()

    // Logic
    private web3Modal: Web3Modal
    private provider?: ethers.providers.Web3Provider
    private didConnectOnLoad: boolean = false
    private connector: any

    private _chain = new ChainModel(
        25,
        "Cronos",
        "Crypto.org Coin",
        18,
        "CRO",
        ['https://evm.cronos.org']
    )

    constructor() {
        const providerOptions = {
            "custom-walletconnect": {
                display: {
                    logo: "https://docs.walletconnect.com/img/walletconnect-logo.svg",
                    name: "WalletConnect",
                    description: "Connect with any WalletConnect compatible wallet."
                },
                options: {
                    appName: 'DooNFT Launchpad dApp',
                    networkUrl: 'https://mmf-rpc.xstaking.sg',
                    chainId: 25
                },
                package: WalletConnectProvider,
                connector: async () => {
                    const connector = new WalletConnectProvider({
                        rpc: {
                            25: "https://mmf-rpc.xstaking.sg"
                        },
                        chainId: 25,
                    });
                    await connector.enable();
                    this.connector = connector;
                    return connector;
                }
            },
        }

        this.web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions
        })
    }

    isCorrectChainId = () => {
        if (window.ethereum) return window.ethereum.networkVersion == this._chain.id
        return true
    }

    connectToCachedProvider = async () => {
        if (this.didConnectOnLoad || !this.web3Modal.cachedProvider) return

        this._isLoading$.next(true)
        this.didConnectOnLoad = true
        this.web3Modal.connectTo(this.web3Modal.cachedProvider)
            .then(provider => {
                this.walletConnected(provider)
                this._isLoading$.next(false)
            })
            .catch(error => {
                this._errors$.next("Failed to connect")
                this._isLoading$.next(false)
            })
    }

    toggleConnect = async () => {
        if (this._connected$.value) {
            try {
                this.connector.disconnect();
            }
            catch (error) {
            }
            try {
                this.connector.deactivate();
            }
            catch (error) {
            }

            this.disconnect()
        } else {
            this.connectToWallet()
        }
    }

    switchNetwork = async () => {
        if (this.isCorrectChainId()) return

        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: ethers.utils.hexValue(this._chain.id) }]
            })
        } catch (err: any) {
            if (err.code == 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainName: this._chain.name,
                        chainId: ethers.utils.hexValue(this._chain.id),
                        nativeCurrency: { name: this._chain.currencyName, decimals: this._chain.decimals, symbol: this._chain.symbol },
                        rpcUrls: this._chain.rpcUrls
                    }]
                })
            } else if (err.code == 4901) {
                return
            } else {
                this._errors$.next('There was a problem adding ' + this._chain.name + ' network to MetaMask')
            }
        }
    }

    private walletConnected(provider: any) {
        this.provider = new ethers.providers.Web3Provider(provider)
        this._connected$.next(true)
        this._showToast$.next({ title: "Wallet connected" })
        this.getAccount()

        this.observeWalletChanges()
    }

    private observeWalletChanges() {
        this.removeListeners()

        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")

        provider.on("network", (newNetwork, oldNetwork) => {
            if (oldNetwork) {
                window.location.reload()
            }
        })

        window.ethereum.on("accountsChanged", (accounts: string[]) => {
            if (accounts[0] && accounts[0] != this._account$.value) {
                this.getAccount()
            }
        })
    }

    private removeListeners() {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
        provider.removeAllListeners()
    }

    private connectToWallet = async () => {
        this.web3Modal.clearCachedProvider()

        this._isLoading$.next(true)
        this.web3Modal.connect()
            .then(provider => {
                this._isLoading$.next(false)
                this.didConnectOnLoad = true
                this.walletConnected(provider)

            })
            .catch(error => {
                this._isLoading$.next(false)
                this._errors$.next("Failed to connect")
            })
    }

    private disconnect = async () => {
        this.web3Modal.clearCachedProvider()
        this._connected$.next(false)
        this._account$.next(undefined)
        this._mintInfo$.next(undefined)
    }

    private getAccount = async () => {
        if (!this.provider) return

        this._isLoading$.next(true)
        const signer = this.provider.getSigner();
        const address = (await signer.getAddress()).toLowerCase();
        this._account$.next(address)
        this._isLoading$.next(false)
    }

    // MINTING
    mint = async (amount: number) => {
        if (!this.provider || !this._account$.value) {
            this.connectToWallet()
            return
        }

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer)

        this._isLoading$.next(true)
        try {
            const price = await contract.price()
            const value = parseInt(ethers.utils.formatEther(price)) * amount

            const gasEstimated = await contract.estimateGas.mint(amount, { value: ethers.utils.parseEther(value.toString()) })
            const gas = Math.ceil(gasEstimated.toNumber() * 1.5)
            const gasNumber = BigNumber.from(gas)

            const tx = await contract.mint(amount, { value: ethers.utils.parseEther(value.toString()), gasLimit: gasNumber })
            await tx.wait()

            this._showToast$.next({ title: 'Congratulations!' })
            this.getMintInfo()
        } catch (e) {
            console.error(e)
            this._errors$.next("Could not process transaction")
        } finally {
            this._isLoading$.next(false)
        }
    }

    getMintInfo = async () => {
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            const mintInfo = await contract.getMintInfo()
            this._mintInfo$.next(mintInfo)
        } catch { }
    }

    // GALLERY
    getUserTokens = async (address: string | undefined) => {
        if (!this.provider || !this._account$.value) return

        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, this.provider)

        try {
            const tokens = await contract.tokensOfWallet(address ?? this._account$.value)
            this._tokens$.next(tokens.map(t => Number(t)))
        } catch (e) {
            //
        }
    }

    // ROYALTIES
    getRoyalties = async () => {
        this._royalties$.next({ totalRoyalties: 0, availableToClaim: 0 })

        if (!this._account$.value) return

        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            const availableToClaim = Number(ethers.utils.formatEther(await contract.getRoyalties(this._account$.value)))
            const totalRoyalties = Number(ethers.utils.formatEther(await contract.totalRoyalties()))

            this._royalties$.next({ totalRoyalties, availableToClaim })
        } catch (e) {
            //
        }
    }

    claimAllRoyalties = async () => {
        if (!this.provider || !this._account$.value) return

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer)

        try {
            const tx = await contract.claimAllRoyalties()
            await tx.wait()

            this._showToast$.next({ title: "Royalties have been claimed" })
        } catch (e) {
            this._errors$.next("Could not claim royalties")
        }
    }
}