import {
  Box,
  Center
} from "@chakra-ui/react";

import MintHeader from "./MintHeader";

const HeroView: React.FC = () => {
  return (
    <>
      <Box pt={{ base: 8, md: 16 }} pb={16}>
        <Box
          backgroundColor={"white"}
          width={320}
          height={320}
          position={"absolute"}
          left={"50%"}
          transform={"translate(-50%, 0)"}
          zIndex={-1}
          filter={"auto"}
          blur={"320px"}
          borderRadius={320}
        />

        <Box position={"relative"}>
          <Center>
            <Box
              bg={'rgba(0, 0, 0, 0.5)'}
              rounded={"xl"}
              boxShadow={"md"}
              maxWidth={720}
              p={4}
              zIndex={2}
            >
              <MintHeader />
            </Box>
          </Center>
        </Box>
      </Box>
    </>
  );
}

export default HeroView;