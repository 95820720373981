export class ChainModel {
    id: number;
    name: string;
    currencyName: string;
    decimals: number;
    symbol: string;
    rpcUrls: string[];

    constructor(id: number,
        name: string,
        currencyName: string,
        decimals: number,
        symbol: string,
        rpcUrls: string[]) {
        this.id = id;
        this.name = name;
        this.currencyName = currencyName;
        this.decimals = decimals;
        this.symbol = symbol;
        this.rpcUrls = rpcUrls;
    }
}
