import { Avatar, Box, Flex, Heading, Image } from "@chakra-ui/react";

interface NFTItemViewProps {
  name: string,
  image: string
}

const NFTItemView: React.FC<NFTItemViewProps> = (props) => {
  const { name, image } = props;

  return (
    <>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          position={'relative'}
          direction="column"
          justifyContent="center"
          alignItems="center"
          w="sm"
          mx="auto">

          <Image src={image} boxSize={{ base: '80%', md: '100%' }} css={{
            border: '3px solid rgb(255, 255, 255)',
          }} />

          <Box
            position={'absolute'}
            top={'5%'}
            right={'5%'}
            zIndex={2}
            bg='rgba(0, 0, 0, 0.7)'
            mt={0}
            shadow="lg"
            rounded="sm"
            px={4}
            borderRadius={'lg'}
            overflow="hidden"
          >
            <Heading
              py={2}
              textAlign="center"
              fontWeight="bold"
              fontSize={'sm'}
              textTransform="uppercase"
              color="white"
              letterSpacing={1}
            >
              {name}
            </Heading>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default NFTItemView;
